import React from 'react'
import { graphql } from 'gatsby'
import { Container, Col, Row } from 'reactstrap'

import SiteDocumentations from '../../../components/SiteDocumentations'

import SEO from '../../../components/seo'

import Hunter from './hunter.png'

class hunter extends React.Component {
  render() {

    const seoData = {
      title: "Documentation produits d'irrigation Hunter",
      description: "Consultez notre documentation des produits d'irrigation Hunter",
      coverImage: Hunter,
      path: this.props.location.pathname,
      schemaOrg: ''
    }

    return (
      <div>
        <SEO seoData={seoData} />
        <Container>
          <Row style={{alignItems: "center", marginBottom: 10}}>
            <Col lg={2} md={2} xs={6}>
              <img src={Hunter} alt="Hunter" />
            </Col>
            <Col lg={10} md={10} xs={12}><h1>Manuels d’instruction Hunter</h1></Col>
          </Row>
          <SiteDocumentations {...this.props} vendor='hunter' />
        </Container>
      </div>
    )
  }
}

export default hunter

export const hunterDocumentationQuery = graphql`
  query HunterDocumentation {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
    remark: allMarkdownRemark(
      filter: { frontmatter: { vendor: { eq: "Hunter" } } }
    ) {
      documentations: edges {
        documentation: node {
          id
          html
          frontmatter {
            layout
            title
            path
            coverImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
